import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { LKF, RIB, GRT, GRE } from 'global/tenant-config/tenants';
import { useMutation, useQueryClient } from 'react-query';
import { useModal } from 'store/modals';
import { submitServiceReportAPI } from './api';
import { mapDataForBackend, mapDataForBackendRIB, mapDataForBackendLKF } from './mapData';

const REPORTED_CASES = 'reportedCases';

export function useSubmitServiceReport({ onSuccess }) {

  const queryClient = useQueryClient();
  const tenantId = getTenantIDBasedOnURL().toLowerCase();

  const { openModal } = useModal();

  const dataToBackend = (report) => {
    switch (tenantId) {
      case RIB:
      case GRT:
      case GRE:
        return mapDataForBackendRIB(report);
      case LKF:
        return mapDataForBackendLKF(report);
      default:
        return mapDataForBackend(report);
    }
  };

  const { mutate, isLoading, isSuccess, isError } = useMutation(
    (report) => submitServiceReportAPI(dataToBackend(report)),
    {
      onSuccess: () => {
        openModal({
          modalType: 'NotificationModal',
          modalProps: {
            type: 'success',
            description: 'serviceReportSuccess',
          },
        });
        queryClient.invalidateQueries([REPORTED_CASES, tenantId]);
        onSuccess();
      },
      onError: () => {
        openModal({
          modalType: 'NotificationModal',
          modalProps: {
            type: 'error',
            description: 'serviceReportFail',
          },
        });
      },
    },
  );

  return {
    submitServiceReport: mutate,
    isLoading,
    isSuccess,
    isError,
  };
}
