import React from 'react';
import { Text } from 'components/typography/Text';
import { FormattedMessage } from 'react-intl';
import { Brikk } from 'components/Brikk';
import { tenantConfig, getTenantIDBasedOnURL } from 'global/tenant-config';
import { RIB } from 'global/tenant-config/tenants';

export const ContactInformation = () => {
  const tenantId = getTenantIDBasedOnURL().toLowerCase();
  return (
    <Brikk.Heading heading="callUs">
      <Text
        element="span"
        large
      >
        {
              tenantId !== RIB ? (
                <FormattedMessage
                  id="contactUsThirdColumnDescription"
                  values={{
                    number: (...chunks) => (
                      <a
                        href={`tel:${chunks}`}
                      >
                        {chunks}
                      </a>
                    ),
                    email: (...chunks) => (
                      <a
                        href={`mailto:${chunks}`}
                      >
                        {chunks}
                      </a>
                    ),
                    bold: (...chunks) => (
                      <Text
                        element="span"
                        bold
                        large
                      >
                        {chunks}
                      </Text>
                    ),
                    small: (...chunks) => (
                      <Text
                        element="span"
                        medium
                        bold
                      >
                        {chunks}
                      </Text>
                    ),
                    map: (...chunks) => (
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href={tenantConfig.officeLocation}
                      >
                        {chunks}
                      </a>
                    ),
                    h3: (...chunks) => (
                      <Text
                        uppercase
                        element="h3"
                        size={22}
                      >
                        {chunks}
                      </Text>
                    ),
                    website: (...chunks) => (
                      <a
                        href={`${chunks}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {chunks}
                      </a>
                    ),
                  }}
                />
              ) : (
                <>
                  <FormattedMessage
                    id="contactUsThirdColumnDescription"
                    values={{
                      a: (...chunks) => (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="http://riksbyggen.se/kundtjanst"
                        >
                          {chunks}
                        </a>
                      ),
                      number: (...chunks) => (
                        <a
                          href={`tel:${chunks}`}
                        >
                          {chunks}
                        </a>
                      ),
                      bold: (...chunks) => (
                        <span style={{ fontWeight: 700 }}>
                          {chunks}
                        </span>
                      ),
                    }}
                  />
                  <FormattedMessage
                    id="contactUsThirdColumnDescription2"
                    values={{
                      a: (...chunks) => (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="http://www.riksbyggen.se"
                        >
                          {chunks}
                        </a>
                      ),
                      email: (...chunks) => (
                        <a
                          href={`mailto:${chunks}`}
                        >
                          {chunks}
                        </a>
                      ),
                      bold: (...chunks) => (
                        <span style={{ fontWeight: 700 }}>
                          {chunks}
                        </span>
                      ),
                    }}
                  />
                  <FormattedMessage
                    id="contactUsThirdColumnDescription3"
                    values={{
                      number: (...chunks) => (
                        <a
                          href={`tel:${chunks}`}
                        >
                          {chunks}
                        </a>
                      ),
                      a: (...chunks) => (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="http://riksbyggen.se/kundtjanst"
                        >
                          {chunks}
                        </a>
                      ),
                      email: (...chunks) => (
                        <a
                          href={`mailto:${chunks}`}
                        >
                          {chunks}
                        </a>
                      ),
                    }}
                  />
                </>
              )
}
      
      </Text>
    </Brikk.Heading>
  );
};
