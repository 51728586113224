import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CircularImage } from 'components/brikks/sub-components/CircularImage';
import entryfyImage from 'assets/media/access-app/access-app-white-background.png';
import my2NImage from 'assets/media/access-app/2Napp.png';
import styles from 'components/brikks/home/AccessApp/components/AccessAppPreview/styles.module.scss';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { GRE } from 'global/tenant-config/tenants';

export const AccessAppPreview = () => {
  const tenantId = getTenantIDBasedOnURL().toLowerCase();

  const text = tenantId === GRE ? 'My2N' : 'entryfy';
  const image = tenantId === GRE ? my2NImage : entryfyImage;
  return (

    <div>
      <CircularImage
        src={image}
        alt="Access app image"
        className={styles.imageBg}
      />

      <div className={styles.textLine}>
        <FormattedMessage id={text} />
      </div>

    </div>
  );
};
