import React from 'react';
import { Grid } from 'components/layout/Grid';
import { Brikk } from 'components/Brikk';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { ModalIntro } from 'components/layout/ModalIntro';
import accessAppImg from 'assets/media/access-app/access-phone-girl.png';
import { Manuals } from 'components/brikks/home/AccessApp/components/AccessAppModal/components/Manuals';
import { Download } from 'components/brikks/home/AccessApp/components/AccessAppModal/components/Download';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { GRE } from 'global/tenant-config/tenants';

export const AccessAppModal = () => {
  const tenantId = getTenantIDBasedOnURL().toLowerCase();

  return (
    <Grid brikkLayout>
      <IntlPageTitle intlTitle="accessApp" />

      <Grid.Column>
        <Brikk.Heading
          main
          heading="accessApp"
        >
          <ModalIntro>
            <ModalIntro.Image
              src={accessAppImg}
              alt="Access app"
            />

            <ModalIntro.Text intl="accessAppDescription" />
          </ModalIntro>
        </Brikk.Heading>
      </Grid.Column>

      {tenantId !== GRE ? (
        <Grid.Column>
          <Brikk.Heading heading="list">
            <Manuals />
          </Brikk.Heading>
        </Grid.Column>
      ) : null}

      <Grid.Column>
        <Brikk.Heading heading="download">
          <Download />
        </Brikk.Heading>
      </Grid.Column>

    </Grid>
  );
};
