import React from 'react';
import { Text } from 'components/typography/Text';
import { Image } from 'components/media/Image';
import entryfyImgSrc from 'assets/media/access-app/entryfy.png';
import My2Nimage from 'assets/media/access-app/2Napp.png';
import iosDownloadBadge from 'assets/media/ios-download-badge.svg';
import androidDownloadBadge from 'assets/media/google-play-badge.png';
import { ExternalLink } from 'components/links/ExternalLink';
import styles from 'components/brikks/home/AccessApp/components/AccessAppModal/components/Download/styles.module.scss';
import { getTenantIDBasedOnURL } from 'global/tenant-config';

export const Download = () => {
  const tenatId = getTenantIDBasedOnURL().toUpperCase();
  const normalizedTenantId = tenatId.toUpperCase();

  const tenantConfig = {
    GRE: {
      image: My2Nimage,
      iosHref: 'https://itunes.apple.com/us/app/2n-mobile-video/id1188403431?ls=1&mt=8',
      androidHref: 'https://play.google.com/store/apps/details?id=com.nn.my2ncommunicator',
    },
    default: {
      image: entryfyImgSrc,
      iosHref: 'https://apps.apple.com/se/app/entryfy/id1497879952',
      androidHref: 'https://play.google.com/store/apps/details?id=com.entryfy&hl=en_US&gl=US',
    },
  };

  const config = tenantConfig[normalizedTenantId] || tenantConfig.default;

  return (
    <>
      <Text
        intl="accessAppDownloadDescription"
        className={styles.appDescription}
        size={18}
      />

      <div className={styles.appImageContainer}>
        <Image 
          src={config.image} 
        />
      </div>

      <div className={styles.downloadBadges}>
        <ExternalLink href={config.iosHref}>
          <Image
            src={iosDownloadBadge}
            className={styles.icon}
          />
        </ExternalLink>
        <ExternalLink href={config.androidHref}>
          <Image
            src={androidDownloadBadge}
            className={styles.iconAndroid}
          />
        </ExternalLink>
      </div>
    </>
  ); 
};
